import { defineStore } from "pinia";
import { utilGetWeaTher } from "../utils/util";

export const weaTherUseStore = defineStore({
    id: 'myWeaTherStore',
    state: () => ({
        weaTher: {
            cloud: "0", // 云量，百分比数值。可能为空
            dew: "0", // 露点温度。可能为空
            feelsLike: "0", // 体感温度，默认单位：摄氏度
            humidity: "0", //相对湿度，百分比数值
            icon: "0", //天气状况和图标的代码，图标可通过天气状况和图标下载
            obsTime: "", // 数据观测时间
            precip: "0.0", //当前小时累计降水量，默认单位：毫米
            pressure: "0", // 大气压强，默认单位：百帕
            temp: "0", // 温度，默认单位：摄氏度
            text: "", //天气状况的文字描述，包括阴晴雨雪等天气状态的描述
            vis: "0", // 能见度，默认单位：公里
            wind360: "0", // 风向360角度
            windDir: "", // 风向
            windScale: "0", // 风力等级
            windSpeed: "0", // 风速，公里/小时
            category: "", // 空气质量
        }
    }),

    actions: {
        async initWeaTher() {
            let weaTherData = await utilGetWeaTher();
            if(weaTherData.length) {
                let [ a, b ] = weaTherData;
                let weaTherCount = {
                    ...a,
                    ...b
                }
                for(let i in this.weaTher) this.weaTher[i] = weaTherCount[i]
            }
        }
    }
})
