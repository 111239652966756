<template>
  <div class="run-mode-page">
    <div class="run-mode-top">
      <div class="card-title" style="margin-top: 0;">累计运动方式统计</div>
      <div class="export-label-btn" @click="openDialogAlert">导出</div>
    </div>
    <div class="canvas-desc flex align-center justify-end">
      <div class="flex align-center">
        <i style="background: #f53f8c"></i>
        <span>跑步人次</span>
      </div>
      <div class="flex align-center">
        <i style="background: #52ffff"></i>
        <span>步行人次</span>
      </div>
    </div>
    <div class="run-mode-canvas" id="run-mode-canvas">
      <div id="mode-canvas"  ref="runCanvas"></div>
    </div>

    <el-dialog v-model="dialogFormVisible" center title="运动数据导出" style="width: 500px">
      <el-form label-position="right" label-width="auto">
        <!-- 地点 -->
        <el-form-item label="地点：" v-show="locationList.length > 1">
          <el-select v-model="locationId" placeholder="请选择">
            <el-option v-for="(item, index) in locationList" :key="index" :label="item.locationName" :value="item.id"/>
          </el-select>
        </el-form-item>
        <!-- 日期范围 -->
        <el-form-item label="日期范围：">
          <el-date-picker
          v-model="dataTime"
          type="daterange"
          unlink-panels
          value-format="YYYY-MM-DD"
          range-separator="至"
          start-placeholder="起始时间"
          end-placeholder="截止时间"
          :shortcuts="shortcuts"
        />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="extendRunData">
            导出
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { getDimRUnMode, exportRunData } from '@/api/count';
import { getLocationPage } from '@/api/index';
import { getDay } from '@/utils/util';
import { onMounted, ref } from 'vue';
import { ElMessage } from 'element-plus';
// 引入echarts
import * as echarts from "echarts";

onMounted(() => {
    getDimRUnMode({startTime: getDay(-6), endTime: getDay()}).then(res => {
        let runDateArr = res.map(item => item.dimRun);
        let walkDateArr = res.map(item => item.dimWalk);
        let xData = res.map(item => item.startTime.split('-')[2])

        initEchart(xData,walkDateArr,runDateArr)

    })
    initLocation()
})

const locationList = ref([])
const locationId = ref('')
const initLocation = () => {
  getLocationPage({ current: 1, size: 999}).then(res => {
    console.log('获取的地点列表', res)
    locationList.value = res.records
    if (res.records.length === 1) locationId.value = res.records[0].id
  })
}

const dialogFormVisible = ref(false)
const dataTime = ref('')
const shortcuts = [
  {
    text: '最近一周',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    },
  },
  {
    text: '最近一个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    },
  },
  {
    text: '最近三个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    },
  },
]

const openDialogAlert = () => {
  dataTime.value = ''
  dialogFormVisible.value = true
}

const extendRunData = () => {
  if (!dataTime.value) return ElMessage.error('请选择导出日期范围')
  let url = exportRunData(
    locationId.value,
    dataTime.value[0],
    dataTime.value[1]
  )
  open(url)
  dialogFormVisible.value = false
}

const runCanvas = ref(null)
// 初始化Echarts
const initEchart = (xData,optionWalkArr,optionRunArr) => {
  let option = {
    xAxis: {
      data: xData,
      type: "category",
      axisTick: {
        show: false,
      },
      boundaryGap: false,
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: "#fff",
        fontSize: 12,
        lineHeight: 20,
      },
      axisLine: {
        lineStyle: {
          color: "rgba(12,102,173,.5)",
          // width: 20,
        },
      },
    },
    yAxis: {
      type: "value",
      axisTick: {
        show: false, //不显示刻度线
      },
      axisLabel: {
        color: "#fff", //y轴上的字体颜色
        fontSize: 12,
        lineHeight: 20,
      },
      axisLine: {
        lineStyle: {
          width: 2,
          color: "rgba(12,102,173,.5)", //y轴的轴线的宽度和颜色
        },
      },
      splitLine: {
        show: false,
      },
    },
    grid: {
      left: "4%",
      right: "4%",
      top: "5%",
      bottom: "1%",
      containLabel: true,
    },
    series: [
      //  跑步人次
      {
        data: optionRunArr,
        type: "line",
        symbol: "none",
        smooth: true,
        itemStyle: {
          normal: {
            color: "#E24F95",
          },
        },
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, .1, [
              {
                offset: 0,
                color: "#E24F95",
              },
              {
                offset: 1,
                color: "rgba(226,79,149,.01)",
              },
            ]),
          },
        },
      },
      // 步行人次
      {
        data: optionWalkArr,
        type: "line",
        symbol: "none",
        smooth: true,
        itemStyle: {
          normal: {
            color: "#3A92A1",
          },
        },
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, .1, [
              {
                offset: 0,
                color: "#3A92A1",
              },
              {
                offset: 1,
                color: "rgba(58,146,161,.1)",
              },
            ]),
          },
        },
      },
    ],
  };
  let runModeCanvas = echarts.init(runCanvas.value);
  runModeCanvas.setOption(option);
};


</script>

<style lang="scss" scoped>
.run-mode-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .run-mode-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: .175rem;
      .export-label-btn {
        color: #409EFF;
        font-size: .2rem;
        cursor: pointer;
      }
    }
}
.canvas-desc {
    width: 100%;
    margin: 0.25rem 0 0.375rem;
    & > div {
      i {
        width: 0.25rem;
        height: 0.1rem;
        background: #f53f8c;
        border-radius: 0.05rem;
        margin-right: 0.125rem;
      }
      font-size: 0.225rem;
      color: white;
      line-height: 1;
      margin-right: 0.375rem;
    }
  }
  .run-mode-canvas {
    width: 100%;
    flex: 1;
    overflow: hidden;
    #mode-canvas {
      width: 100%;
      height: 100%;
    }
  }

</style>
